<template>
  <!-- 法律诉讼 -->
  <div class="container" style="margin-top: 80px;min-height: 500px;">
    <div class="recruitinfo-title">
      <div style="margin-bottom: 20px;" class="dis-bet-cen">
        <span class="font-title">{{item.casename || '-'}}</span>
      </div>
      <div class="dis-bet-cen">
        <span class="font-content">案件编号：{{item.caseno}} | 案件类型：{{item.casetype}} ｜ 发布日期：{{item.submitdate}}</span>
      </div>
    </div>
    <div class="recruitinfo-content">
      <div class="font-title" style="margin-bottom: 10px;">文书正文</div>
      <div v-html="item.fmtContent"></div>
    </div>
  </div>
</template>

<script>
import { forwardBG } from '@/api/forward.js'
export default {
  data () {
    return {
      item: {}
    }
  },
  mounted () {
    const vm = this
    const itemStr = sessionStorage.getItem('law-mes-item')
    if (itemStr) {
      const item = JSON.parse(itemStr)
      console.log(item)
      vm.item = item
      vm.init()
    }
  },
  methods: {
    init () {
      const vm = this
      const data = {
        dateBackgroundUrl: '/companyCredit/getJudgementDetailByJudgementId',
        dateBackgroundRequestType: 'get',
        data: {
          judgementId: vm.item.id
        }
      }
      forwardBG(data).then(result => {
        if (result.code === 0) {
          const res = result.data
          console.log(res)
          // vm.TableData = arr
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped="scoped" lang="less">
.recruitinfo-title {
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #ebebeb;
}
.recruitinfo-content {
  background-color: #fff;
  padding: 20px;
}
.font-title {
  font-weight: bold;
  font-size: 20px;
  color: #333;
  text-align: left;
}
.font-content {
  font-size: 16px;
  color: #999999;
}
</style>
